
import cabinExtImg1 from './cabin_pictures/exterior/Full-1.jpg'
import cabinExtImg2 from './cabin_pictures/exterior/Full-2.jpg'
import cabinExtImg3 from './cabin_pictures/exterior/Full-3.jpg'
import cabinExtImg4 from './cabin_pictures/exterior/Full-4.jpg'
import cabinExtImg5 from './cabin_pictures/exterior/Full-5.jpg'
import cabinExtImg6 from './cabin_pictures/exterior/Full-6.jpg'
import cabinExtImg7 from './cabin_pictures/exterior/Full-7.jpg'
import cabinExtImg8 from './cabin_pictures/exterior/Full-8.jpg'
import cabinExtImg9 from './cabin_pictures/exterior/Full-9.jpg'
import cabinExtImg10 from './cabin_pictures/exterior/Full-10.jpg'
import cabinExtImg11 from './cabin_pictures/exterior/Full-11.jpg'
import cabinExtImg12 from './cabin_pictures/exterior/Full-12.jpg'
import cabinExtImg13 from './cabin_pictures/exterior/Full-13.jpg'
import cabinExtImg14 from './cabin_pictures/exterior/Full-14.jpg'

import cabinIntImg1 from './cabin_pictures/interior/Full-1.jpg'
import cabinIntImg2 from './cabin_pictures/interior/Full-2.jpg'
import cabinIntImg3 from './cabin_pictures/interior/Full-3.jpg'
import cabinIntImg4 from './cabin_pictures/interior/Full-4.jpg'
import cabinIntImg5 from './cabin_pictures/interior/Full-5.jpg'
import cabinIntImg6 from './cabin_pictures/interior/Full-6.jpg'
import cabinIntImg7 from './cabin_pictures/interior/Full-7.jpg'
import cabinIntImg8 from './cabin_pictures/interior/Full-8.jpg'
import cabinIntImg9 from './cabin_pictures/interior/Full-9.jpg'
import cabinIntImg10 from './cabin_pictures/interior/Full-10.jpg'
import cabinIntImg11 from './cabin_pictures/interior/Full-11.jpg'
import cabinIntImg12 from './cabin_pictures/interior/Full-12.jpg'
import cabinIntImg13 from './cabin_pictures/interior/Full-13.jpg'
import cabinIntImg14 from './cabin_pictures/interior/Full-14.jpg'
import cabinIntImg15 from './cabin_pictures/interior/Full-15.jpg'
import cabinIntImg16 from './cabin_pictures/interior/Full-16.jpg'
import cabinIntImg17 from './cabin_pictures/interior/Full-17.jpg'
import cabinIntImg18 from './cabin_pictures/interior/Full-18.jpg'
import cabinIntImg19 from './cabin_pictures/interior/Full-19.jpg'
import cabinIntImg20 from './cabin_pictures/interior/Full-20.jpg'

const images = [
  cabinExtImg7,
  cabinExtImg1, 
  cabinExtImg2,
  cabinExtImg3,
  cabinExtImg4,
  cabinExtImg5,
  cabinExtImg6,
  cabinExtImg8,
  cabinExtImg9,
  cabinExtImg10,
  cabinExtImg11,
  cabinExtImg12,
  cabinExtImg13,
  cabinExtImg14,
  cabinIntImg1,
  cabinIntImg2,
  cabinIntImg3,
  cabinIntImg4,
  cabinIntImg5,
  cabinIntImg6,
  cabinIntImg7,
  cabinIntImg8,
  cabinIntImg9,
  cabinIntImg10,
  cabinIntImg11,
  cabinIntImg12,
  cabinIntImg13,
  cabinIntImg14,
  cabinIntImg15,
  cabinIntImg16,
  cabinIntImg17,
  cabinIntImg18,
  cabinIntImg19,
  cabinIntImg20,
]

export default images