import React from 'react'
// import './tailwind.output.css'
import images from './Images'
import Nav from './Nav'
import Footer from './Footer'

function App() {
  return (
    <div>
      <Nav />
      <div className="h-24"></div>
      <div className='max-w-screen-xl mx-auto'>
        {images.map((image, ix) => (
          <div key={`img-${ix}`} className="mx-6 mb-6">
            <img alt={`Cabin ${ix}`} id={`img-${ix}`} className="shadow-md rounded-lg object-scale-down mx-auto" src={image} />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default App
