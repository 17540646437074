import React from 'react'

const Nav = () => {
  return (
      <div className="z-0 w-full fixed bg-white mb-4">
        <div className="relative z-10 shadow">
          <div className="max-w-screen-xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
            <div>
              <a href="#" className="flex">
                PaysonAZCabin.com
              </a>
            </div>
            <div className="flex-1 flex items-center justify-between space-x-12">
              <nav className="flex space-x-10">
                
              </nav>
              <div className="flex items-center space-x-8">
                {/* <button type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
                  <span>Book Now</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
        </div>
  )
}

export default Nav