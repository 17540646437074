import React from 'react'

const Footer = () => {
  return (
  <footer className="bg-white">
    <div className="max-w-screen-xl mx-auto pb-8 px-4 sm:px-6 lg:px-8">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        {/* <div className="grid grid-cols-2 gap-8 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
                Solutions
              </h4>
              <ul className="mt-4 space-y-4">
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Marketing
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Analytics
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Commerce
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Insights
                  </a>
                </li>
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
                Support
              </h4>
              <ul className="mt-4 space-y-4">
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Documentation
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Guides
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    API Status
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
                Company
              </h4>
              <ul className="mt-4 space-y-4">
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    About
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Jobs
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Press
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Partners
                  </a>
                </li>
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </h4>
              <ul className="mt-4 space-y-4">
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Claim
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Privacy
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Terms
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>*/}
        
      </div>
      <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
        <div className="flex space-x-6 md:order-2">
          {/* <div className="mt-8 xl:mt-0">
            <h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
              Subscribe to our newsletter
            </h4>
            <p className="mt-4 text-base leading-6 text-gray-500">
              The latest news, articles, and resources, sent to your inbox weekly.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label for="emailAddress" className="sr-only">Email address</label>
              <input type="email" id="emailAddress" required className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base leading-6 text-gray-900 placeholder-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline focus:placeholder-gray-400 transition duration-150 ease-in-out" placeholder="Enter your email" />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button type="submit" className="w-full bg-indigo-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Subscribe
                </button>
              </div>
            </form>
          </div> */}
        </div>
        <p className="text-base leading-6 text-gray-400 md:mt-0 md:order-1">
          &copy; 2020 PaysonAZCabin.com - Hoyst LLC.
        </p>
      </div>
    </div>
  </footer>
  
  )
}

export default Footer